import React from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import ImgSection from '../components/rows/imgsection'
import TwoCol from '../components/rows/twocol'

import Banner from '../images/img/hero/research-hero.jpg'

export default function OurResearch({data}) { // <== Make sure data is sent to the function

    return (
        <Layout>
        <Seo title="Research & Innovation" />
        <Hero 
            title="Our Research"
            banner={Banner}
        />
        {data.allStrapiOurResearch.edges.map(({ node }) => ( // <== Map out the data from the node.
            <> 
              <div className="intro py-56">
                  <Intro key={node.research_intro.id}
                      title={node.research_intro.title}
                      subtitle={node.research_intro.subtitle}
                  />
                  <TwoCol 
                      white
                      key={node.research_twocol.id}
                      column1={node.research_twocol.description_1}
                      column2={node.research_twocol.description_2}
                  />
                </div>
                <ImgSection key={node.research_img.id}
                    subtitle={node.research_img.subtitle}
                    title={node.research_img.title}
                    description={node.research_img.description}      
                    image={node.research_img.image.localFile.publicURL}
                />
                <ImgSection key={node.research_img2.id}
                    white
                    subtitle={node.research_img2.subtitle}
                    title={node.research_img2.title}
                    description={node.research_img2.description}      
                    image={node.research_img2.image.localFile.publicURL}
                />
               
            </>
        ))}            
        </Layout>    
    )
}


export const query = graphql`
query Research {
    allStrapiOurResearch {
      edges {
        node {
          research_intro {
            id
            title
            subtitle
          }
          research_twocol {
            id
            description_1
            description_2
          }
          research_img {
            id
            subtitle
            title
            description
            image {
              localFile {
                publicURL
              }
            }
          }
          research_img2 {
            id
            subtitle
            title
            description
            image {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`